export function tableSmart() {
	document.querySelectorAll("table").forEach(function (table) {
		const wrapper = document.createElement("div");
		wrapper.classList.add("table-wrap");
		table.parentNode.insertBefore(wrapper, table);
		wrapper.appendChild(table);
	});

	// Add event listeners to sort buttons
	document.querySelectorAll("[data-sort-button]").forEach(function (button) {
		button.addEventListener("click", function (event) {
			event.stopPropagation();
			const columnIndex = button.closest("th").getAttribute("data-sort");
			sortTable(columnIndex);
		});
	});

	// Add event listeners to search inputs
	document.querySelectorAll("[data-search-input]").forEach(function (input) {
		input.addEventListener("focus", function () {
			clearOtherInputs(input);
		});

		input.addEventListener("keyup", function () {
			const columnIndex = input.getAttribute("data-search-input");
			filterTable(columnIndex);
		});
	});

	function clearOtherInputs(currentInput) {
		document.querySelectorAll("[data-search-input]").forEach(function (input) {
			if (input !== currentInput) {
				input.value = ""; // Clear other inputs
			}
		});
		filterTable(currentInput.getAttribute("data-search-input")); // Re-filter table
	}

	function sortTable(columnIndex) {
		var table = document.querySelector("table[data-animate]");
		var rows = Array.from(table.rows).slice(2); // Skip header and search rows
		var dir = table.getAttribute("data-sort-dir") === "asc" ? "desc" : "asc";
		table.setAttribute("data-sort-dir", dir);

		rows.sort(function (a, b) {
			// Check if rows are hidden
			var displayA = a.style.display === "none" ? 1 : 0;
			var displayB = b.style.display === "none" ? 1 : 0;

			// If both rows are visible or both are hidden, sort by content
			if (displayA === displayB) {
				var cellA = a.cells[columnIndex].textContent.trim().toLowerCase();
				var cellB = b.cells[columnIndex].textContent.trim().toLowerCase();

				if (cellA < cellB) return dir === "asc" ? -1 : 1;
				if (cellA > cellB) return dir === "asc" ? 1 : -1;
				return 0;
			}

			// Always move hidden rows to the bottom
			return displayA - displayB;
		});

		rows.forEach(function (row) {
			table.querySelector("tbody").appendChild(row);
		});
	}

	function filterTable(columnIndex) {
		var input, filter, table, tr, td, i, txtValue;
		input = document.querySelector('[data-search-input="' + columnIndex + '"]');
		filter = input.value.toLowerCase();
		table = document.querySelector("table[data-animate]");
		tr = table.getElementsByTagName("tr");

		var hiddenRows = [];

		for (i = 2; i < tr.length; i++) {
			// Start from 2 to skip the header and search rows
			td = tr[i].getElementsByTagName("td")[columnIndex];
			if (td) {
				txtValue = td.textContent || td.innerText;
				if (txtValue.toLowerCase().indexOf(filter) > -1) {
					tr[i].style.display = "";
				} else {
					tr[i].style.display = "none";
					hiddenRows.push(tr[i]);
				}
			}
		}

		// Move hidden rows to the bottom
		hiddenRows.forEach(function (row) {
			table.querySelector("tbody").appendChild(row);
		});
	}
}
